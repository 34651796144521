import React, { Component } from 'react';

/* ADDS REDIRECT TO UNIFORM V3 DOCSITE */
export default class V3Redirect extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate('http://uniform.hudltools.com/');
  }

  render() {
    return <div />;
  }
}
